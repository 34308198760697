import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Custom Domains with Heroku",
  "date": "2015-07-11T00:00:00.000Z",
  "published": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`I recently rewrote the `}<a parentName="p" {...{
        "href": "http://mtldribbblemeetup.com"
      }}>{`Montreal Dribbble Meetup website`}</a>{` using Sinatra. The previous version was simply static HTML and CSS files, so hosting and using a custom domain name was easy.`}</p>
    <p>{`Since I moved to something dynamic (for various reasons), I decided to host the website on `}<a parentName="p" {...{
        "href": "http://heroku.com"
      }}>{`Heroku`}</a>{` for simplicity reasons. Pushing something to `}<a parentName="p" {...{
        "href": "http://heroku.com"
      }}>{`Heroku`}</a>{` is beautifully simple, but unfortunately looking at the `}<a parentName="p" {...{
        "href": "http://heroku.com"
      }}>{`Heroku`}</a>{` documentation, I discovered that `}<a parentName="p" {...{
        "href": "https://www.hover.com"
      }}>{`Hover`}</a>{`, where I had my domain name doesn’t support setting a `}<inlineCode parentName="p">{`CNAME`}</inlineCode>{` for root domains. Bummer.`}</p>
    <p>{`Fortunately, `}<a parentName="p" {...{
        "href": "http://heroku.com"
      }}>{`Heroku`}</a>{` provides a list of DNS services that support that feature. Looking at the list, the only two names that rang a bell were DNS Simple and `}<a parentName="p" {...{
        "href": "https://www.cloudflare.com"
      }}>{`CloudFlare`}</a>{`. DNS Simple’s pricing structure seems uh… anything but simple. So I went with `}<a parentName="p" {...{
        "href": "https://www.cloudflare.com"
      }}>{`CloudFlare`}</a>{`. For my needs, the free `}<a parentName="p" {...{
        "href": "https://www.cloudflare.com"
      }}>{`CloudFlare`}</a>{` plan was more than enough.`}</p>
    <p>{`The setup process is surprisingly pretty seamless. You enter your domain name and they import your existing DNS records and tell you exactly what to replace in order to switch over to `}<a parentName="p" {...{
        "href": "https://www.cloudflare.com"
      }}>{`Cloudflare`}</a>{`. Kudos for the amazingly simple onboarding experience.`}</p>
    <p>{`Once that was done, all I had to do was add a `}<inlineCode parentName="p">{`CNAME`}</inlineCode>{` record with my domain name (`}<a parentName="p" {...{
        "href": "http://mtldribbblemeetup.com"
      }}>{`mtldribbblemeetup.com`}</a>{`) pointing to the `}<a parentName="p" {...{
        "href": "http://heroku.com"
      }}>{`Heroku`}</a>{` URL (`}<a parentName="p" {...{
        "href": "http://mtldribbblemeetup.com"
      }}>{`mtldribbblemeetup.herokuapp.com`}</a>{`). Done!`}</p>
    <p>{`The whole process took roughly 10 minutes and the site was already accessible at the custom domain name. Couldn’t recommend `}<a parentName="p" {...{
        "href": "https://www.cloudflare.com"
      }}>{`Cloudflare`}</a>{` enough as a DNS provider for sites hosted on Heroku.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      